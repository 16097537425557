import { Injectable } from '@angular/core';

export const environment = {
  production: false
};

const INCIDENT_CONFIG_TABLE:string="incident-config";
const INCIDENT_REPORT_TABLE:string="snow-api-stats";
const UPDATED_INCIDENT_REPORT_TABLE:string="snow-update-stats";
const API_ACCESS_TABLE:string="snow-api-access";
const TEAM_TABLE:string="sro-site-team-table";
const USER_TABLE:string="sro-site-user-access-table";
const SERVICE_TABLE:string="sro-site-service-table";
const ASSOCIATE_TEAM_APP_TABLE:string="sro-site-team-assoc-table";
const APP_URL:string="https://srosnowsite.sro-team-test.nikecloud.com/home/dashboard";
const USER_STATS_TABLE:string="sro-ui-user-stats";
const CLIENT_ID:string="nike.sre.sresite-app";
const DB_ENDPOINT = "https://sro-team-test.nikecloud.com/sre-ui-service/";
const SNASS_API:string = "https://sro-team-test.nikecloud.com/snaas-main-framework/api/v1/";
@Injectable()
export class Environment {


    constructor() {
    }


    getEndpoint(): string{
        return DB_ENDPOINT;
    }

    getIncidentConfigTable(): string{
        return INCIDENT_CONFIG_TABLE;
    }
    getIncidentReportTable(): string{
        return INCIDENT_REPORT_TABLE;
    }
    getUpdatedIncidentReportTable(): string{
        return UPDATED_INCIDENT_REPORT_TABLE;
    }
    getApiAccessTable(): string{
        return API_ACCESS_TABLE;
    }
    getTeamTable(): string{
        return TEAM_TABLE;
    }
    getUserTable(): string{
        return USER_TABLE;
    }
    getServiceTable(): string{
        return SERVICE_TABLE;
    }
    getAssocTeamandAppsTable(): string{
        return ASSOCIATE_TEAM_APP_TABLE;
    }
    getUserStatsTable(): string{
        return USER_STATS_TABLE;
    }
    Isproduction():Boolean{
        return false;
    }
    //This method returns a flag which controls the automatic authentication from Okta. Below flag has to be set as true only for local debugging.
    IsAutoLoginModeEnabled():Boolean{
        return true; //set this as false before test or prod deployment.
    }
    getAppId():string{
        return "nike.sre.sresite-app";
    }
    getClientId():string{
        return CLIENT_ID;
    }
    getAppLocalHostURL():string{
        
       return "http://srosnowsite.sro-team-test.nikecloud.com/login";
    }
    getSnowURL():string{
        // return "https://nikedev.service-now.com/nav_to.do?uri=%2Fincident.do%3Fsys_id%3D#sysId#%26sysparm_stack%3D%26sysparm_view%3D";
        return "https://nikeuat.service-now.com/incident.do?sys_id=#sysId#&sysparm_view=&sysparm_view_forced=true";
    }
    getOktaURL():string{
        return "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7";
    }
    getAppURL():string{
        return  APP_URL;
    }
    getSnassapi():string{
        return SNASS_API;
    }
}