import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule }                    from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//To check the idle user
import { BnNgIdleService } from 'bn-ng-idle';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { AppComponent }      from './app.component';
import { AppRoutingModule }  from './app.routing';

import { HttpModule }              from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';

// Angular2 -> Semantic Bridge
import {SuiModule} from 'ng2-semantic-ui';

//Config
import { Environment } from '../environments/environment';
// import { Environment } from '@env/environment';
// Services
import { EditorStateService }   from './service/editorstate.service';
import { SessionService }       from './service/session.service';
import { AuthService }          from './service/auth.service';
import { AuthGuardService }  from './service/auth-guard.service'
import { LoggedInGuard }        from './login.guard';
import { ManagementIO }         from './service/management.io';
import { AdministrationIO }     from './service/administration.io'
import { InsertdataService }     from './service/insertdata.service';
import { UpdatedataService } from './service/updatedata.service';
import { FetchcommondataService } from './service/fetchcommondata.service';
import { ChartsModule } from 'ng2-charts';
import {UserstatisticsService} from './service/userstatistics.service';
import {RestService} from './service/rest.service';

// Components
import { LoginComponent }      from './login/login.component';
import { HomeComponent  }      from './modules/app-home/home.component';
import { SystemhealthComponent } from './modules/systemhealth/systemhealth.component';
import { Globals } from './globals/globals';

@NgModule({
  imports: [
    SuiModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    ChartsModule,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SystemhealthComponent
  ],
  providers: [
    EditorStateService,
    SessionService,
    Environment,
    AuthService,
    AuthGuardService,
    LoggedInGuard,
    ManagementIO,
    AdministrationIO,
    InsertdataService,
    UpdatedataService,
    FetchcommondataService,
    FetchcommondataService,
    Globals,
    DatePipe,
    BnNgIdleService,
    UserstatisticsService,
    RestService
  ],
  bootstrap: [ AppComponent ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {

}
