import { Injectable,Inject } from '@angular/core';
import * as AWS from 'aws-sdk';
import { Environment } from './../../environments/environment';
import {RestService} from '../service/rest.service';

@Injectable()
export class UserstatisticsService {
visit_count:number=1;
insert_count:number=1;
update_count:number=1;
user_stats_table:string;
endpoint:string;
  constructor(@Inject(Environment) private env: Environment,private restservice:RestService) {
          this.user_stats_table=env.getUserStatsTable();
          this.endpoint=env.getEndpoint();
   }

   insertUser(username:string,callback: Function){
     console.log("inside insert user");
    this.restservice.insertuserinuserstats(username).subscribe(
      (data) => callback(data),
      (err) => console.log("abcd",err));
   }
   getUserStatsDatabyId(useremail:string,callback:Function)    {
    this.restservice.getuserstatsdatabyid(useremail).subscribe( (data) => callback(data),(error)=>callback(error));
    console.log("in angular ser");
    //console.log(this.result);
    }
   updateVisitCount(username:string,callback: Function){
    this.restservice.updateuservisitcount(username).subscribe((data) => callback(data),(error)=>callback(error));
   }
   updateInsertCount(username:string,callback: Function){
    this.restservice.updateuserinsertcount(username).subscribe((data) => callback(data),(error)=>callback(error));
   }
   updateUpdateCount(username:string,callback: Function){
    this.restservice.updateuserupdatecount(username).subscribe((data) => callback(data),(error)=>callback(error));
   }
}
