// Imports
import { Injectable, Inject } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';

// Key Constants
export class SKEY {
    public static readonly NT_APPGROUP_ID        :string = "SSTRNG_NT_APPGROUP_ID";
    public static readonly NT_APPGROUP_NAME      :string = "SSTRNG_NT_APPGROUP_NM";
    public static readonly NT_APPGROUP_ADMIN_TAB :string = "SSTRNG_NT_APPGROUP_TAB"
}

// Session storage wrapper service
@Injectable()
export class SessionService {
    
    /** In memory value cache. */
    private sessionValues:Map<string,string>;

    /**
     * Injection constructor.
     * @param http Http service
     */
    constructor() { 
        this.sessionValues = new Map();
    }
    
    /**
     * Returns a stored value by key.
     * @param key The key to return the value of.
     */
    public get(key:string): string {

        // Check input
        if(!key) { return null; }

        var value:string = this.sessionValues[key];
        if(value != null) return value;

        value = sessionStorage.getItem(key);
        if(value == null) return null;

        this.sessionValues[key] = value;
        return value;
    }
    
    /**
     * Sets a stored value by key.
     * @param key The key to set the value for.
     * @param value The value to set.
     */
    public set(key:string, value:string): void {
        
        // Check input
        if(!key) { return; }
        
        // If null, remove from storage
        if(!value) {
            sessionStorage.removeItem(key);
            this.sessionValues.delete(key);
            return;
        }

        // Set value
        sessionStorage.setItem(key, value);
        this.sessionValues[key] = value;
    }
}