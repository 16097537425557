import { Component, Inject }                 from '@angular/core';
import { CommonModule }              from '@angular/common';
import { Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";
import {Globals} from '../globals/globals';
import { map, catchError, tap } from 'rxjs/operators';
import { AuthService }               from '../service/auth.service';
import { Environment } from './../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { BnNgIdleService } from 'bn-ng-idle';
import {RestService} from '../service/rest.service';
import { HttpClient,HttpHeaders,HttpResponse }    from '@angular/common/http';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';
// TODO: Replace URL link with function to get current template mapping
@Component({
  selector: 'login',
  templateUrl: 'login.main.html',
  styleUrls : [ 'login.main.css']
})
export class LoginComponent {
    
    loginError       : boolean = false;
    loginBeingCalled : boolean = false;
    invalidAuthentication:boolean = false;
    invalidAuthMsg : string = "";
    appUrlLink : string ="";
    visitCount : number = 0;
    /**
     * Component constructor
     */
    constructor(private http: HttpClient,
                @Inject(AuthService) private authService: AuthService, 
                @Inject(Router) private router: Router,private globals: Globals,
                private env: Environment,@Inject(DOCUMENT) private document: any,
                private bnIdle: BnNgIdleService,private restservice:RestService) {}
    
    ngOnInit(): void {  
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search.slice(1));
      const code = searchParams.get('code');
      const error = searchParams.get('error');
      if(error){
        alert(error);
    }
    else if(!code){
        this.okta_redirect();
    }
    else {
      
      this.get_bearer();
    }                
   }
  
   getRandomString(length) {
    let a = new Uint8Array(Math.ceil(length / 2));
    crypto.getRandomValues(a);
    let str = Array.from(a, dec => ('0' + dec.toString(16)).substr(-2)).join('');
    return str.slice(0, length);
  }
  makeVerifier() {
  const minLength = 43;
  const maxLength = 128;
  let verifier = '';
  if (verifier.length < minLength) verifier = verifier + this.getRandomString(minLength - verifier.length);
  return encodeURIComponent(verifier).slice(0, maxLength);
  }
  async get_bearer(){
    
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search.slice(1));
    const code = searchParams.get('code');
    const codeVerifier = localStorage.getItem('code_verifier');
    
    try {
      const httpoptions = {headers:new HttpHeaders(
      {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    
    const params = new URLSearchParams();
    params.set('code', code);
    params.set('code_verifier', codeVerifier);
    params.set('grant_type', 'authorization_code');
    params.set('redirect_uri', this.env.getAppLocalHostURL());
    params.set('client_id', this.env.getClientId());
      return this.http.post(this.env.getOktaURL()+'/v1/token', params.toString(), httpoptions)
      .subscribe((res:Response) =>{
        let body=JSON.stringify(res);
        let bodyparse=JSON.parse(body);
         sessionStorage.setItem('okta_token',bodyparse.access_token);
         sessionStorage.setItem('id_token',bodyparse.id_token);
         console.log(bodyparse.id_token);
         console.log(bodyparse.access_token);
         this.authService.validateLogin((isvalid:Boolean)=>{
         if(isvalid)
         {
          const httpoptions1 = {headers:new HttpHeaders(
            {
              'Authorization': 'Bearer '+sessionStorage.getItem('okta_token')
            })
          };
          //let headers=new HttpHeaders().set('Authorization', 'Bearer '+sessionStorage.getItem('okta_token'));
          this.http.get(this.env.getOktaURL()+'/v1/userinfo',httpoptions1)
          .subscribe((res:Response) =>{
            let body=JSON.stringify(res);
            let bodyparse=JSON.parse(body);             
             sessionStorage.setItem('user',bodyparse.name);
            },(err: any) => {
            this.invalidAuthentication = true;
            this.invalidAuthMsg = "You have been logged out";
          });
          this.validateUserAccess(this.globals);
         }
         else
         {
          this.invalidAuthentication = true;
          this.invalidAuthMsg = "You have been logged out";
         }
        });
            },   
                    (err: any) => {
                    this.invalidAuthentication = true;
      this.invalidAuthMsg = "You have been logged out";
                  });
                // .subscribe((res:Response) =>{console.log("response:",res);});
    } catch (e) {
      this.invalidAuthentication = true;
      this.invalidAuthMsg = "You have been logged out";
    }
}

base64URLEncode(input) {
  return btoa(input)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

// Note that this function is async, because of the asynchronous nature of web crypto's digest method
async makeChallenge(str) {
  const buffer = new TextEncoder().encode(str);
  const arrayBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const hash = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
  return this.base64URLEncode(hash);
}
async okta_redirect(){
  const codeVerifier = this.makeVerifier();
  const params = new URLSearchParams();
  localStorage.setItem('code_verifier', codeVerifier);
  const codeChallenge = await this.makeChallenge(codeVerifier);
  params.set('client_id', this.env.getClientId());
  params.set('redirect_uri', this.env.getAppLocalHostURL());
  params.set('response_type', 'code');
  params.set('state', 'OR');
  params.set('code_challenge', codeChallenge);
  params.set('code_challenge_method', 'S256');
  params.set('scope', 'profile openid email');
  const auth_url = this.env.getOktaURL()+'/v1/authorize?' + params.toString();
  window.location.href = auth_url;
}
validateUserAccess(globals:Globals){
            if(this.authService.isSuperAdmin() || this.authService.isUser() || this.authService.isReadOnlyUser()){
              this.invalidAuthentication = false;
              this.invalidAuthMsg = "";
              this.authService.isValidSession((isValidSession:Boolean) =>{
                  if(isValidSession){
                    this.invalidAuthentication = false;
                    this.invalidAuthMsg = "";
                    if(this.authService.isSuperAdmin() && (this.authService.isUser() || this.authService.isReadOnlyUser())){
                      this.authService.validateUserAccessLevel((resultval: any) => {
                            if(resultval == true){
                              // Navigate to the home page
                              //this.router.navigate(['home/dashboard']); 
                              this.router.navigate(['home/dashboard']); 
                          }
                          else{
                            // redirect user to manage user screen to assign to a specific team and app id's
                              this.router.navigate(['home/manageuser'],{ queryParams: { userFound: 'false' }});
                          }
                      });
                    }
                    else
                    if((this.authService.isUser() || this.authService.isReadOnlyUser()) && !(this.authService.isSuperAdmin())){                     
                      this.authService.validateUserAccessLevel((resultval: any) => {
                            if(resultval == true){
                              // Navigate to the home page
                              //this.router.navigate(['home/dashboard']); 
                              this.router.navigate(['home/dashboard']);                         
                          }
                          else{
                            // redirect user to manage user screen to assign to a specific team and app id's
                              this.router.navigate(['home/manageuser'],{ queryParams: { userFound: 'false' }});
                              //code to disable all for user access
                          }
                      });
                    }
                    else{
                       // Navigate to the home page
                      //this.router.navigate(['home']);
                      this.router.navigate(['home/manageuser'],{ queryParams: { userFound: 'false' }});
                    }
                  }
                  else{
                    this.invalidAuthentication = true;
                    this.invalidAuthMsg = globals.invalidSession;
                  }   
              });
            }
            
            else{
              this.invalidAuthentication = true;
              this.invalidAuthMsg = globals.invalidRole;
            }
}
}
 
      