// Imports
import { Injectable, Inject } from '@angular/core';

@Injectable()
/**
 * This class manages the state of data being edited in templates. It tracks the lifecycle of
 * data across adding, loading, editing, saving, and deleting. Set the state here and
 * using the isXXX methods in your templates' conditionals to control which forms / fields are
 * displayed. 
 */
export class EditorStateService {

    private addingFlags = new Set<string>();
    private editingFlags = new Set<string>();
    private savingFlags = new Set<string>();
    private loadingFlags = new Set<string>();
    private deletingFlags = new Set<string>();

    private buildId(type : string, selector? : any) : string {
        if (selector !== null) {
            return type + '.' + selector;
        }

        return type;
    }

    /**
     * Are you adding a value on a form?
     * 
     * @param type the type of value, e.g.: 'redirect_url' or 'audience'; use any constant you like so long as you are consistent
     */
    isAdding(type : string) {
        return this.addingFlags.has(this.buildId(type));
    }

    /**
     * Call this method to start adding a value
     * 
     * @param type the type of value you are adding
     */
    startAdding(type : string) {
        this.addingFlags.add(this.buildId(type));
    }

    /**
     * Call this method when done adding the value if you will save it
     * 
     * @param type the value's type
     */
    doneAdding(type : string) {
        this.addingFlags.delete(this.buildId(type));
    }

    /**
     * Call this method when done adding the value if you will not save the value
     * 
     * @param type the value type
     */
    cancelAdding(type : string, selector? : any) {
        this.addingFlags.delete(this.buildId(type, selector));
    }

    /**
     * Are you editing a value on a form?
     * 
     * @param type same as for isAdding
     * @param selector an optional indicator of which value of the given type is being edited. Pass the original value that is being edited.
     */
    isEditing(type : string, selector? : any) {
        return this.editingFlags.has(this.buildId(type, selector));
    }

    /**
     * Start editing a value
     * 
     * @param type
     * @param selector use the original value that is being edited
     */
    startEditing(type : string, selector? : any) {
        this.editingFlags.add(this.buildId(type, selector));
    }

    /**
     * Indicate you have finished editing the value
     * 
     * @param type 
     * @param selector use the original value, not the new value
     */
    doneEditing(type : string, selector? : any) {
        this.editingFlags.delete(this.buildId(type, selector));
    }

    /**
     * Call this method if you stop editing without saving
     * @param type 
     * @param selector the original value, not the new value
     */
    cancelEditing(type : string, selector? : any) {
        this.editingFlags.delete(this.buildId(type, selector));
    }

    /**
     * Removes all editing flags
     */
    doneWithAllEdits() {
        this.editingFlags.clear();
    }

    /**
     * Cancels all edits
     */
    cancelAllEdits() {
        this.editingFlags.clear();
    }
    
    /**
     * 
     * @param type true if save is in progress
     * @param selector 
     */
    isSaving(type?:string, selector?:any) : boolean {
        
        if(!type && !selector) {
            return this.savingFlags.size > 0;
        }

        return this.savingFlags.has(this.buildId(type, selector));
    }

    /**
     * marks as save in progress
     * @param type 
     * @param selector 
     */
    startSaving(type : string, selector? : any) {
        this.savingFlags.add(this.buildId(type, selector));
    }

    /**
     * mark save as done
     * @param type 
     * @param selector the original value
     */
    doneSaving(type?:string, selector?:any) {
        if(!type && !selector) {
            this.savingFlags.clear();
            return;
        }

        this.savingFlags.delete(this.buildId(type, selector));
    }

    /**
     * Cancel saving
     * @param type 
     * @param selector original value
     */
    cancelSaving(type : string, selector? : any) {
        this.savingFlags.delete(this.buildId(type, selector));
    }

    /**
     * Cancel all saving regardless of type/selector
     */
    cancelAllSaving() {
        this.savingFlags.clear();
    }

    /**
     * true if loading is in progress
     * @param type 
     * @param selector 
     */
    isLoading(type : string, selector? : any) {
        return this.loadingFlags.has(this.buildId(type, selector));
    }

    /**
     * flag as loading
     * @param type 
     * @param selector
     */
    startLoading(type : string, selector? : any) {
        this.loadingFlags.add(this.buildId(type, selector));
    }

    /**
     * remove loading flag
     * @param type 
     * @param selector original value
     */
    doneLoading(type : string, selector? : any) {
        this.loadingFlags.delete(this.buildId(type, selector));
    }

    /**
     * cancels loading 
     * @param type 
     * @param selector original value
     */
    cancelLoading(type : string, selector? : any) {
        this.loadingFlags.delete(this.buildId(type, selector));
    }

    /**
     * true if delete in progress
     * @param type 
     * @param selector 
     */
    isDeleting(type : string, selector? : any) {
        return this.deletingFlags.has(this.buildId(type, selector));
    }

    /**
     * flags as deleting
     * @param type 
     * @param selector value to delete
     */
    startDeleting(type : string, selector? : any) {
        this.deletingFlags.add(this.buildId(type, selector));
    }
    /**
     * flags as done deleting
     * @param type 
     * @param selector value that was deleted
     */
    doneDeleting(type : string, selector? : any) {
        this.deletingFlags.delete(this.buildId(type, selector));
    }

    /**
     * cancels delete operation
     * @param type 
     * @param selector original value being deleted
     */
    cancelDeleting(type : string, selector? : any) {
        this.deletingFlags.delete(this.buildId(type, selector));
    }
}
