import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent} from '../app/login/login.component';
import { HomeComponent} from '../app/modules/app-home/home.component';

import { LoggedInGuard } from '../app/login.guard';

const appRoutes: Routes = [

    { path: '', pathMatch: 'full', redirectTo: 'home' },
    { path: 'home', component: HomeComponent, canActivate: [LoggedInGuard], loadChildren: 'app/modules/app-home/home.module#HomeModule' },
    { path: 'login', component: LoginComponent },

    { path: '**', redirectTo: 'home' }
];

@NgModule({
    imports: [
         RouterModule.forRoot(appRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {}