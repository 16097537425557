import { Injectable,Inject } from '@angular/core';
import * as AWS from 'aws-sdk';
import { SplunkConfigModel} from '../modules/splunk-on-board/splunk-on-board.models';
import { SignalfxConfigModel} from '../modules/signalfx-on-board/signalfx-on-board.models';
import {NewRelicConfigModel} from '../modules/newrelic-on-board/newrelic-on-board.models';
import { CloudwatchConfigModel} from '../modules/cloudwatch-on-board/cloudwatch-on-board.models';
import { OnBoardedAppsModel} from '../modules/manage-app-id/manage-app-id.models';
import { ManageTeamModel } from '../modules/manageteam/manageteam.models';
import { AssociateTeamAppsModel } from '../modules/associateteamapps/associateteamapps.models';
import { ManageServiceModel } from '../modules/manageservice/manageservice.models';
import { ManageUserModel } from '../modules/manageuser/manageuser.models';
import { Environment } from './../../environments/environment';
import { SplunkconfigdataService } from './splunkconfigdata.service';
import { AuthService }               from '../service/auth.service';
import {UserstatisticsService} from '../service/userstatistics.service';
import {RestService} from '../service/rest.service';

@Injectable()
export class UpdatedataService {
  // splunkconfigtablename:string;
  incidentconfigtablename:string;
  apiaccesstablename:string;
  teamtablename:string;
  usertablename:string;
  servicetablename:string;
  teamapptablename:string;
  isActiveval:Boolean;
  endpoint:string;
  constructor(@Inject(Environment) private env: Environment,private restservice:RestService,
  @Inject(UserstatisticsService) private userstatisticsService:UserstatisticsService,
  @Inject(AuthService) private authService: AuthService) { 
      this.incidentconfigtablename=env.getIncidentConfigTable();
      this.apiaccesstablename = env.getApiAccessTable();
      this.teamtablename = env.getTeamTable();
      this.usertablename = env.getUserTable();
      this.servicetablename = env.getServiceTable();
      this.teamapptablename = env.getAssocTeamandAppsTable();
      this.endpoint=env.getEndpoint();
    }
   

    updateSplunkConfig(splunkconfig:SplunkConfigModel,callback: Function) {
      this.restservice.updatesplunkconfig(splunkconfig).subscribe( (data) => callback(data),(error)=>callback(error));   
    }
    updateSignalfxConfig(signalfxconfig:SignalfxConfigModel,callback: Function) {
      this.restservice.updatesignalfxconfig(signalfxconfig).subscribe( (data) => callback(data),(error)=>callback(error));
    }
    updateNewrelicConfig(newrelicconfig:NewRelicConfigModel,callback: Function) {
      this.restservice.updatenewrelicconfig(newrelicconfig).subscribe( (data) => callback(data),(error)=>callback(error));
    }
    updateCloudwatchConfig(cloudwatchconfig:CloudwatchConfigModel,callback: Function) {
      this.restservice.updatecloudwatchconfig(cloudwatchconfig).subscribe( (data) => callback(data),(error)=>callback(error));
    }
    updateTeamAppDtls(teamname:string,appname:string,callback:Function) {
      this.restservice.updateteamapp(teamname,appname).subscribe( (data) => callback(data),(error)=>callback(error));
    }
    updateManageUser(manageuser:ManageUserModel,callback: Function) {
      this.restservice.updatemanageuser(manageuser).subscribe( (data) => callback(data),(error)=>callback(error));   
    }
    updateSlackRuleConfig(slackconfig:any,callback: Function) {
      this.restservice.updateslackruleconfig(slackconfig).subscribe( (data) => callback(data),(error)=>callback(error));   
    }

}