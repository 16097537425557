import { Component } from '@angular/core';

@Component({
  selector: 'my-app',
  templateUrl: 'app.main.html'
})
export class AppComponent {
  constructor(){
    // prints: http://development.server.com
    // prints: http://production.server.com if the state is production

  
    // prints: http://development.server.com/api/v1/users
    // prints: http://production.server.com/api/v1/users if the state is production
}
}
