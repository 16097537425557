import { Injectable,Inject } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService }  from './auth.service';
@Injectable()
export class AuthGuardService implements CanActivate{
  activate:boolean=false;
  constructor(@Inject(AuthService) private authService: AuthService, public router: Router) {}

  canActivate(): boolean {
    if(this.authService.isSuperAdmin())
    {
      this.activate=true;
    }
    else{
      this.authService.validateUserAccessLevel((resultval: any) => {
        console.log("inside table access");
        if(resultval == true){
               console.log("user is having access to table"+resultval);   
              this.activate=true;       
        }
        else{
          this.activate=false;
        }
      });
    }
      console.log(this.activate);
      return this.activate;
    }
  }