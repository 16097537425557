import { Injectable, Inject }                      from '@angular/core';
import { Router }                                  from '@angular/router';
import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { AuthService }                             from '../service/auth.service';
import { Environment } from './../../environments/environment';
/**
 *
 */
Injectable()
export class AdministrationIO {

    private baseURL: string;

    /**
     *
     */
    constructor(@Inject(Http)        private http: Http,
                @Inject(AuthService) private authService: AuthService,
                @Inject(Environment) private env: Environment,
                @Inject(Router)      private router: Router) {

        //this.baseURL = env.getAdministrationUrl();
    }

    /**
     * Generic error handler.
     */
    private handleError (error: Response | any, callback: Function) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            try {
                const body = error.json() || '';//this will fail miserably if response isn't JSON
                const err = body.error || JSON.stringify(body);
                errMsg = `${err}`;
            } catch (e) {
                console.log("Failed to parse error response for " + error.toString());
                errMsg = error.statusText; // falling back to plain text error response
            }
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.log('handleError', errMsg);
        callback(errMsg);
    }

    /**
     * Performs a POST operation
     */
    // public doPost(endpoint: string, body: any, callback: Function) {

    //     var url = this.baseURL + endpoint;

    //     this.authService.token((err: any, token: String) => {

    //         if(err) {
    //             this.authService.logout();
    //             this.router.navigate(['login']);
    //             return;
    //         }

    //         let headers = new Headers(
    //         {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + token,
    //             'appId': 'authManagementUI'
    //         });

    //         let options = new RequestOptions({ headers: headers });

    //         this.http.post(url, body, options)
    //             .subscribe((res: Response) => {
    //                 callback(null, res);
    //             },
    //             (err: any) => this.handleError(err,callback));
    //     });
    // }

}