import { Injectable, Inject } from '@angular/core';
import * as AWS from 'aws-sdk';
import { Environment } from './../../environments/environment';
import { Observable} from 'rxjs';
import { of } from 'rxjs/observable/of';
import {Globals} from '../globals/globals';
import {RestService} from '../service/rest.service';

@Injectable()
export class FetchcommondataService {
  /* public batchGetItemsfromDynamo(let params):Product[]{
    return this.productdata;
  } */
  incidentreporttablename:string;
  updatedincidentreporttablename:string;
  apiaccesstablename:string;
  teamtablename:string;
  usertablename:string;
  servicetablename:string;
  teamapptablename:string;
  // splunkconfigtablename:string;
  incidentconfigtablename:string;
  // slackconfigtablename:string;
  userstatstablename:string;
  endpoint:string;
  result:any;
  constructor(@Inject(Environment) private env: Environment,private globals: Globals,private restservice:RestService) { 
    this.incidentreporttablename = env.getIncidentReportTable();
    this.apiaccesstablename = env.getApiAccessTable();
    this.teamtablename = env.getTeamTable();
    this.usertablename = env.getUserTable();
    this.servicetablename = env.getServiceTable();
    this.teamapptablename = env.getAssocTeamandAppsTable();
    this.updatedincidentreporttablename = env.getUpdatedIncidentReportTable();
    // this.splunkconfigtablename = env.getSplunkConfigTable();
    this.incidentconfigtablename = env.getIncidentConfigTable();
    // this.slackconfigtablename = env.getSlackConfigTable();
    this.userstatstablename = env.getUserStatsTable();
    this.endpoint=env.getEndpoint();
  }
getAppNames(callback:Function)    {
    this.restservice.getonlyappnamesdata().subscribe( (data) => callback(data),(error)=>callback(error));
} 
getAppNamesForUser(useremail:string,callback:Function)    {
  console.log("app names for user");
  this.restservice.getappnamesdataforuser(useremail.toLowerCase()).subscribe( (data) => callback(data),(error)=>callback(error));
} 
getTeamAppData(callback:Function)    {
  this.restservice.getteamassocdata().subscribe( (data) => callback(data),(error)=>callback(error));
}
getTeamData(callback:Function)    {
  this.restservice.getteamsdata().subscribe( (data) => callback(data),(error)=>callback(error));
}
getDashboardCount(callback:Function){
  this.restservice.getdashboardcountdata().subscribe( (data) => callback(data),(error)=>callback(error));
}
getTeamCount(callback:Function)    {
  this.restservice.getteamscount().subscribe( (data) => callback(data),(error)=>callback(error));
}
getServiceCount(callback:Function)    {
  this.restservice.getservicescount().subscribe( (data) => callback(data),(error)=>callback(error));
}
getAppIDCount(callback:Function)    {
  this.restservice.getappidcount().subscribe( (data) => callback(data),(error)=>callback(error));
}
getCreatedIncidentCount(callback:Function)    {
  this.restservice.getcreatedincidentcount().subscribe( (data) => callback(data),(error)=>callback(error));
}
getCreatedIncidentCountBasedOnUserAppId(params,callback:Function)    {
  var ddb = new AWS.DynamoDB.DocumentClient();
  this.restservice.getcreatedincidentcountbasedonuserapp(params).subscribe( (data) => callback(data),(error)=>callback(error));
}
getUpdatedIncidentCount(callback:Function)    {
  this.restservice.getupdatedincidentcount().subscribe( (data) => callback(data),(error)=>callback(error));
  }
  getUpdatedIncidentCountBasedOnUserAppId(params,callback:Function)    {
    this.restservice.getupdatedincidentcountbasedonuserapp(params).subscribe( (data) => callback(data),(error)=>callback(error));
  }
  getSplunkOnboardCount(callback:Function){
    this.restservice.getsplunkonboardcount().subscribe( (data) => callback(data),(error)=>callback(error));
  }
  getSplunkOnboardCountBasedOnUserAppId(params,callback:Function)    {
    var ddb = new AWS.DynamoDB.DocumentClient();
  this.restservice.getsplunkonboardcountbasedonuserapp(params).subscribe((data) => callback(data),(error)=>callback(error));
  }
  getsignalFxOnboardCount(callback:Function){
    this.restservice.getsignalfxonboardcount().subscribe( (data) => callback(data),(error)=>callback(error));
  }
  getSignalfxOnboardCountBasedOnUserAppId(params,callback:Function)    {
    this.restservice.getsignalfxonboardcountbasedonuserapp(params).subscribe((data) => callback(data),(error)=>callback(error));
  }
  getNewrelicOnboardCount(callback:Function){
    this.restservice.getnewreliconboardcount().subscribe( (data) => callback(data),(error)=>callback(error));
  }
  getNewrelicOnboardCountBasedOnUserAppId(params,callback:Function)    {
    this.restservice.getnewreliconboardcountbasedonuserapp(params).subscribe((data) => callback(data),(error)=>callback(error));
  }
  getcloudwatchOnboardCount(callback:Function){
    this.restservice.getcloudwatchonboardcount().subscribe( (data) => callback(data),(error)=>callback(error));
  }
  getcloudwatchOnboardCountBasedOnUserAppId(params,callback:Function)    {
    this.restservice.getcloudwatchonboardcountbasedonuserapp(params).subscribe((data) => callback(data),(error)=>callback(error));
  }
  getSnaasOnboardCount(callback:Function){
    this.restservice.getsnaasonboardcount().subscribe( (data) => callback(data),(error)=>callback(error));
  }
  getSnaasOnboardCountBasedOnUserAppId(params,callback:Function)    {
    this.restservice.getsnaasonboardcountbasedonuserapp(params).subscribe((data) => callback(data),(error)=>callback(error));
  }
  getSlackOnboardCount(callback:Function){
    this.restservice.getslackonboardcount().subscribe( (data) => callback(data),(error)=>callback(error));
  }

  // getOnBoardData(callback:Function){  
  // //let originval:string = "newrelic";
  // var ddb = new AWS.DynamoDB.DocumentClient();
  // var params = {
  //     ProjectionExpression: 'origin',
  //     TableName: this.incidentconfigtablename      
  //  };
  //  ddb.scan(params, function(err, data) {
  //   if (err) {
  //     console.log("Error", err);
  //   } else {
  //     //console.log("Success", data.Items);
  //     console.log("inside onboard");     
  //     callback(err,data);
  //     // data.Items.forEach(function(element, index, array) {
  //     //   console.log(element.origin.S);
  //     //   callback(err,data);
  //     // });
  //   }     
  // });
  // }

  // getNewrelicOnboardCount(callback:Function){
  //   let originval:string = "newrelic";
  //   var ddb = new AWS.DynamoDB.DocumentClient();
  //   var params = {
  //       ProjectionExpression: '#statusval',
  //       TableName: this.incidentconfigtablename,
  //       FilterExpression: "#statusval = :val1",
  //       ExpressionAttributeNames: { 
  //         "#statusval" : "origin"
  //     },
  //     ExpressionAttributeValues: {
  //         ":val1" : originval
  //     }
  //    };
  //   ddb.scan(params, function(err, data) {
  //     if (err) {
  //       console.log("Error", err);
  //     } else {
  //       //console.log("Success", data.Items);
  //       console.log("inside new relic onboard");
  //       data.Items.forEach(function(element, index, array) {
  //         console.log(element.origin.S);
  //         callback(err,data.Count);
  //       });
  //     }     
  //   });
  // }
  // getsignalFxOnboardCount(callback:Function){
  //   let originval:string = "signalfx";
  //   var ddb = new AWS.DynamoDB.DocumentClient();
  //   var params = {
  //       ProjectionExpression: '#statusval',
  //       TableName: this.incidentconfigtablename,
  //       FilterExpression: "#statusval = :val1",
  //       ExpressionAttributeNames: { 
  //         "#statusval" : "origin"
  //     },
  //     ExpressionAttributeValues: {
  //         ":val1" : originval
  //     }
  //    };
  //   ddb.scan(params, function(err, data) {
  //       console.log("errorval : ",err);
  //       callback(err,data.Count);
  //   });
  // }
  // getcloudwatchOnboardCount(callback:Function){
  //   let originval:string = "cloudwatch";
  //   var ddb = new AWS.DynamoDB.DocumentClient();
  //   var params = {
  //       ProjectionExpression: '#statusval',
  //       TableName: this.incidentconfigtablename,
  //       FilterExpression: "#statusval = :val1",
  //       ExpressionAttributeNames: { 
  //         "#statusval" : "origin"
  //     },
  //     ExpressionAttributeValues: {
  //         ":val1" : originval
  //     }
  //    };
  //   ddb.scan(params, function(err, data) {
  //       callback(err,data.Count);
  //   });
  // }
getServiceData(callback:Function)    {
  this.restservice.getservicesdata().subscribe( (data) => callback(data),(error)=>callback(error));
}
getOnBoardedUserData(callback:Function)    {
  //console.log("getOnBoardedUserData method",this.usertablename);
  this.restservice.getalluseraccessdata().subscribe( (data) => callback(data),(error)=>callback(error));
}

getUserAccessData(useremail:string,callback:Function)    {
  //console.log("startDate",startDate);
  //console.log("user email val :",useremail.toLowerCase());
   //create the ddb object
   //let incidentSearchval:any = "INC";
  this.restservice.getuseraccessdata(useremail.toLowerCase()).subscribe( (data) => callback(data),(error)=>callback(error));
  //console.log(this.result);
  }

  getUserStatsData(callback:Function)    {
    this.restservice.getuserstatsdata().subscribe( (data) => callback(data),(error)=>callback(error));
    }

    getSlackConfigidsForMessagebased(callback:Function) {
      this.restservice.getslackconfigidformessagebased().subscribe( (data) => callback(data),(error)=>callback(error));
    }

   getProductData(callback:Function)    {
      
    var str: string;
    
    var creds: AWS.Credentials;
   
    creds = new AWS.Credentials(str,str);
    creds = new AWS.Credentials(str, str, str);
    //str = creds.accessKeyId;
    
    // Configure the region
    AWS.config.region = 'us-west-2';  //us-west-2 is Oregon
    //create the ddb object
    var ddb = new AWS.DynamoDB();
    var params = {
      RequestItems: {
        'ProductCatalog': {
          Keys: [
            {'Id': {N: '1'}},
            {'Id': {N: '2'}},
            {'Id': {N: '3'}},
            {'Id': {N: '4'}},
            {'Id': {N: '5'}},
            {'Id': {N: '6'}},
            {'Id': {N: '7'}},
            {'Id': {N: '8'}},
            {'Id': {N: '9'}},
            {'Id': {N: '10'}}
          ]
        }
      }
    };
     ddb.batchGetItem(params, function(err, data){
          if (err) { 
            callback(err);
          }
          else{
            callback(data.Responses.ProductCatalog);
          }
      } );

    }
    
  
}
