import { Component, Inject }        from '@angular/core';
import { Router,  NavigationExtras, ActivatedRoute,CanActivate } from '@angular/router';
import { AuthService }               from '../../service/auth.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthGuardService as AuthGuard }               from '../../service/auth-guard.service';
import {UserstatisticsService} from '../../service/userstatistics.service';

@Component({
  selector: 'home',
  templateUrl: './home.main.html',
  styleUrls : [ './home.main.css']
  
})
export class HomeComponent {

    /**
     * Component constructor
     */
param1:string;
constructor(@Inject(AuthService) private authService: AuthService, 
@Inject(AuthGuard) private authguardService: AuthGuard,private route: ActivatedRoute,
 @Inject(Router) private router: Router,
 @Inject(UserstatisticsService) private userstatisticsService:UserstatisticsService,
 private bnIdle: BnNgIdleService) {
                  this.bnIdle.startWatching(3600).subscribe((res) => {
                    if(res) {
                        console.log("session expired");
                        this.authService.logout();
                        this.router.navigate(['login'],{ queryParams: { logout: 'true' }});
                    }
                  })
    }
    ngOnInit()
    {
      this.authguardService.canActivate();
      this.userstatisticsService.getUserStatsDatabyId(this.userName(),(serresp: any) => {
      let body: any;
      if(serresp.statusCode>400){
        return 'Error';
      }
      else{
        if(serresp.body!=undefined){
          this.userstatisticsService.updateVisitCount(this.userName(),(resp:any) => {
            if(resp.statusCode>400){
              console.log("error while updating visit count");
            }
            else{
              console.log("response while updating insert count :",resp.body);}
          });
        }
        else{
          this.userstatisticsService.insertUser(this.userName(),(resp:any) => {
              console.log("after calling insert user"+resp.statusCode);
              if(resp.statusCode<210){
                  this.userstatisticsService.updateVisitCount(this.userName(),(resp:any) => {
                    if(resp.statusCode>400){
                      console.log("error while updating visit count");
                    }
                    else{
                      console.log("response while updating insert count :",resp.body);}
                  });
        }
        else{
          console.log();
        }
      });
      }
    }
    });
      // this.userstatisticsService.insertUser(this.userName().toLowerCase(),(resp:any) => {
      //   console.log("after calling insert user"+resp.statusCode);
      //   if(resp.statusCode>404){
      //       this.userstatisticsService.updateVisitCount(this.userName().toLowerCase(),(resp:any) => {
      //         if(resp.statusCode>400){
      //           console.log("error while updating visit count");
      //         }
      //         else{
      //           console.log("response while updating insert count :",resp.body);}
      //       });
          
      //   }
      //   else{
      //     console.log("response while inserting user :",resp.body);
      //   }
      //   // if(resp.statusCode>400){
      //   //   console.log("error while updating visit count");
      //   // }
      //   // else{
      //   //   console.log("response while updating insert count :",resp.body);}
      // });
    }
    
    /**
     * Logs the current user out.
     */
    logOut(event: Event) {

      // Prevent browser from performing the submit on it's own.
      event.preventDefault();

      // Clear login
      this.authService.logout();

      // Show login page
      this.router.navigate(['login'],{ queryParams: { logout: 'true' }});
    }
    
    hasRole(roleId: string) : boolean {
      return this.authService.hasRole(roleId);
    }
    
    /**
     * 
     */
    isKeyManager() : boolean {
      return this.authService.canManageKeys();
    }
    
    /**
     * 
     */
    isLoginManager() : boolean {
      return this.authService.canManageLogins();
    }

    /**
     * 
     */
    isLockoutManager() : boolean {
      return this.authService.canManageLockout();
    }
    
    /**
     * 
     */
    isSecurityExpert() : boolean {
      return this.authService.hasRole('api.security_expert');
    }

    /**
     * 
     */
    isSuperAdmin() : boolean {
      return this.authService.isSuperAdmin();
    }

    isOnlyUser(): boolean {
      this.param1 = this.route.snapshot.params.userFound;
      return false;
    }
    // isuserAccess():boolean {
    //   console.log("inside user access method");
    //   return this.authguardService.canActivate();
    // }
    /**
     * 
     */
    userName() : string {
      return this.authService.userName();
    }
    
}
